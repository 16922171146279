$(document).ready(function() {
    FastClick.attach(document.body);

    if ($('.refSlider').length) {
        var swiper = new Swiper('.refSlider .swiper-container', {
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            slidesPerView: 'auto',
            centeredSlides: true,
            paginationClickable: true,
            spaceBetween: 0,
            loop: true
        });
    }

    $('#headerNav a').click(function(e) {
        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 500);
    });

    $('#pageFooter .textRight a').click(function(ev) {
        ev.preventDefault();
        var scrollToEl = $(this).attr('href');
        if ($(this).hasClass('opened')) {
            $(this).removeClass('opened');
            $(scrollToEl).slideUp(300);
        } else {
            $(this).addClass('opened');
            $(scrollToEl).slideDown(300);
            setTimeout(function() {
                $('html, body').animate({
                    scrollTop: $(scrollToEl).offset().top
                }, 500);
            }, 301);
        }

    });

    $('.closeSection').click(function(ev) {
        ev.preventDefault();
        var idToClose = $(this).parent().parent().attr('id');
        $('#pageFooter .textRight a[href="#' + idToClose + '"]').removeClass('opened');
        $(this).parent().parent().slideUp(300);
    });

});